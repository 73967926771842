import $ from "jquery";
import startAutoSendToggles from "../../utilities/auto_send_toggles";

const modalIsOpen = () => {
  return $('#billingModal').hasClass("show")
}

const hideCountryFields = (countries) => {
  countries.forEach((country) => {
    const countryFields = $(`#${country}_fields`)
    countryFields.hide()
  })
}

const showCountryFields = (countries = []) => {
  countries.forEach((country) => {
    const countryFields = $(`#${country}_fields`)

    countryFields.show()
  })
}

const disabledInputs = (countries) => {
  countries.forEach((country) => {
    $(`#${country}_fields input, #${country}_fields select`).prop('required', false);
  })
}

const activateInputs = (countries) => {
  countries.forEach((country) => {
    $(`#${country}_fields input, #${country}_fields select`).prop('required', true);
  })
}

const countryConfig = {
  '142': { // Mexico
    show: ["mexico"],
    hide: ["chilean", "colombia"]
  },
  '50': { // ID para Colombia
    show: ["colombia"],
    hide: ["chilean", "mexico"]
  },
  '1': { // ID para Chile
    show: ["chilean"],
    hide: ["colombia", "mexico"]
  }
}

const toggleCountryFields = () => {

  const billingAddressCountry = $("#billing_address_country_id")
  const selectedCountryId = $('#financier_country_select').val();

  billingAddressCountry.val(selectedCountryId);

  const config = countryConfig[selectedCountryId]

  if (config) {
    showCountryFields(config.show);
    hideCountryFields(config.hide);

    if (modalIsOpen()) {
      activateInputs(config.show);
      disabledInputs(config.hide);
    }
  } else {
    hideCountryFields(["colombia", "chilean", "mexico"]);
    disabledInputs(["colombia", "chilean", "mexico"]);
  }

};


const startToggleSections = () => {
  // Ocultar secciones inicialmente
  const insuranceSection = $('.financier-insurance-section');
  const creditSection = $('.financier-credit-section');

  const disableFields = (section) => {
    section.find('input, select, textarea').prop('disabled', true);
  };

  const enableFields = (section) => {
    section.find('input, select, textarea').prop('disabled', false);
  };

  // Inicialmente deshabilitar ambas secciones
  disableFields(insuranceSection);
  disableFields(creditSection);

  const toggleSections = (selectedType) => {
    if (selectedType === 'insurance') {
      insuranceSection.show();
      enableFields(insuranceSection);
      creditSection.hide();
      disableFields(creditSection);
    } else if (selectedType === 'credit') {
      creditSection.show();
      enableFields(creditSection);
      insuranceSection.hide();
      disableFields(insuranceSection);
    }
  };

  // Llamar a toggleSections al cambiar el tipo de financiamiento
  $('#financier_financing_type').on('change', function () {
    const selectedType = $(this).val();
    toggleSections(selectedType);
  });

  // Ejecutar la lógica al cargar la página para manejar la sección visible/invisible según el valor inicial
  const initialSelectedType = $('#financier_financing_type').val();
  toggleSections(initialSelectedType);

}

const startToggleCountries = () => {

  if ($('.admin-financier-form').length) {

    // Ejecutar al cargar la página
    toggleCountryFields();
    startToggleSections();

    // Escuchar el cambio en la selección del país
    $('#financier_country_select').on('change', function () {
      toggleCountryFields();
    });


    // Validación de los campos de dinero
    const moneyFields = [
      '#financier_min_value_cents',
      '#financier_max_value_cents',
      '#financier_fixed_monthly_charge_cents',
      '#financier_variable_monthly_charge_cents',
      '#financier_evaluation_limit_cents'
    ];

    moneyFields.forEach(function (selector) {
      $(selector).on('input', function () {
        let value = $(this).val();

        // Permitir solo números y puntos
        value = value.replace(/[^0-9.]/g, '');

        // Limitar a un solo punto decimal
        const parts = value.split('.');
        if (parts.length > 2) {
          value = parts[0] + '.' + parts.slice(1).join('');
        }

        // Limitar a 999.999.999
        const numericValue = parseFloat(value);
        if (numericValue > 999999999) {
          value = '999999999';
        }

        // Asegurar que sea positivo
        if (numericValue < 0) {
          value = Math.abs(numericValue).toString();
        }

        $(this).val(value);
      });
    });

    // Función para obtener el símbolo de la moneda
    const getCurrencySymbol = (currency) => {
      switch (currency) {
        case 'CLF':
          return 'UF';
        case 'CLP':

        case 'USD':

        default:
          return currency || '-';
      }
    };

    // Escuchar cambios en el selector de moneda
    $('.currency-selector').on('change', function () {
      const selectedCurrency = $(this).val();
      const currencySymbol = getCurrencySymbol(selectedCurrency);

      // Actualizar todos los elementos con el target 'currency-symbol'
      $('[data-currency-target="currency-symbol"]').text(currencySymbol);
    });

    // Ejecutar la lógica al cargar la página para establecer el símbolo correcto
    const initialCurrency = $('.currency-selector').val();
    const initialSymbol = getCurrencySymbol(initialCurrency);
    $('[data-currency-target="currency-symbol"]').text(initialSymbol);
  }
}

$(document).on('turbolinks:load', function () {

  startAutoSendToggles()

  startToggleCountries()


});